export enum enumDeliveryTypes {
	warehouse = 1,
	cdek,
	manager
}

export const localizeDeliveryTypesDoc = {
	warehouse: 'Самовывоз',
	cdek: 'СДЭК',
	manager: 'По согласованию с менеджером'
}

export const localizeDeliveryTypes = {
	warehouse: 'Склад',
	cdek: 'СДЭК',
	manager: 'По согласованию с менеджером'
}

export enum enumOrderDeliveryState {
	waiting = 1,
	tracked,
	shipped,
	received,
	error,
	canceled
}

export enum enumOrderPaymentState {
	waiting = 1,
	paid,
	canceled,
	expired,
	error,
	refunding,
	refunded
}

export enum enumOrderPaymentType {
	bill = 1,
	card_tinkoff
}

export const localizeOrderDeliveryState = {
	waiting: 'Подготовка груза',
	tracked: 'Создана накладная',
	shipped: 'Отправлено',
	received: 'Получено',
	error: 'Ошибка',
	canceled: 'Отменено'
}

export enum enumStorageSetTypes {
	'nw-q',
	'nw-h',
	'q-q',
	'q-h',
	'h-q',
	'h-h',
	'qh-qh'
}

export enum enumLotStates {
	counted,
	completed,
	soldout
}

export const localizeLotStates = {
	counted: 'Посчитана',
	completed: 'Разобрана',
	soldout: 'Распродана'
}

export enum enumProductState {
	described,
	collected,
	shooting,
	published,
	unavailable,
	shooted
}

export const localizeProductStates = {
	described: 'Описан',
	collected: 'Собран',
	shooting: 'Фотографируется',
	published: 'Опубликован',
	unavailable: 'Недоступен',
	shooted: 'Отфотографирован'
}

export enum enumOrdersState {
	creating = 1,
	created,
	confirmed,
	paid,
	collecting,
	collected,
	collected_error,
	ready,
	shipped,
	received,
	closed,
	dispute_started,
	dispute_shipped,
	dispute_finished,
	canceled,
	canceled_error,
	process_error,
	tracked,
	packing
}

export const localizeOrderState = {
	creating: 'Создаётся',
	created: 'Создан',
	confirmed: 'Ожидает оплаты',
	paid: 'Оплачен',
	collecting: 'Собирается',
	collected: 'Собран',
	collected_error: 'Ошибка сборки',
	ready: 'Готов к отправке',
	shipped: 'Отправлен',
	received: 'Получен',
	closed: 'Завершен',
	dispute_started: 'Начат спор',
	dispute_shipped: 'Отправлен обратно',
	dispute_finished: 'Спор завершён',
	canceled: 'Отменён',
	canceled_error: 'Отменён из-за ошибки',
	process_error: 'Ошибка обработки',
	tracked: 'Создана накладная',
	packing: 'Подготовка к отправке'
}

export enum enumOrderVariationsState {
	holded = 1,
	holded_error,
	collecting,
	collected,
	collected_error = 5,
	returned,
	returned_error
}

export const localizeOrderVariationState = {
	holded: 'Зарезервировано',
	holded_error: 'Ошибка резервирования',
	collecting: 'Собирается',
	collected: 'Собрано',
	collected_error: 'Ошибка сборки',
	returned: 'Возврат',
	returned_error: 'Ошибка возврата'
}

export enum enumVariationState {
	counted,
	marked,
	instock,
	outofstock,
	partlymoving,
	moving,
	partlystock
}

export enum enumGenders {
	women,
	men,
	girls,
	boys,
	bulk
}

export const localizeGender = {
	women: 'Женщина',
	men: 'Мужчина',
	girls: 'Девочка',
	boys: 'Мальчик',
	bulk: 'Лоты'
}

export enum enumMediaState {
	uploaded,
	processed,
	archived,
	deleted
}

export enum enumMediaEntityType {
	product
}
