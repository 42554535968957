
import { defineComponent, ref, computed, watch, watchEffect, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/http'
import { enumProductState, localizeGender, localizeProductStates } from '@/constains'
import { prettyPrice } from '@/utils/prettyPrice'
import { getUrlFile } from '@/utils/getUrlImg'
import { IFile } from '@/interfaces'

interface IFormAdd {
	states?: Array<number>
	lots?: Array<string>
	gender?: '' | number
	category?: '' | number
	subCategories?: Array<number>
}

export default defineComponent({
	name: 'productsPage',

	setup() {
		const route = useRoute()
		const router = useRouter()
		const pageNumber = ref<number>(1)
		const products = ref([])
		const count = ref<number>(0)
		const countLoad = 10
		const pageCount = computed<number>(() => Math.ceil(count.value / countLoad))

		const sortTypes = [
			{
				name: 'по дате создания',
				key: 'created'
			},
			{
				name: 'по дате изменения',
				key: 'modified'
			},
			{
				name: 'по статусу',
				key: 'state'
			}
		]
		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		function isFilledArray(array: any) {
			return Array.isArray(array) && array.length
		}

		const lots = ref()
		const categories = ref()
		const subCategories = ref()
		const selectedSortType = ref({
			key: sortTypes[0].key,
			direction: 'DESC'
		})
		const filterForm = ref<IFormAdd>({
			states: [],
			lots: [],
			gender: '',
			category: '',
			subCategories: []
		})
		onBeforeMount(async () => {
			getQueryParams()
			lots.value = await getLotList()
			fetchProductList()
		})

		watch(
			[filterForm, selectedSortType],
			() => {
				pageNumber.value = 1
			},
			{ deep: true }
		)

		watch(
			[filterForm, selectedSortType, pageNumber],
			() => {
				setQueryParams()
				fetchProductList()
			},
			{ deep: true }
		)
		watchEffect(async () => {
			categories.value = await getMainCategoryByGender(filterForm.value.gender)
		})
		watchEffect(async () => {
			subCategories.value = await getSubCategoryByCategoryId(filterForm.value.category)
		})

		function getQueryParams() {
			try {
				const filterFormFromQueryParams = route.query.filterForm
				const selectedSortTypeFromQueryParams = route.query.selectedSortType
				const pageNumberFromQueryParams = route.query.pageNumber
				if (typeof filterFormFromQueryParams === 'string') {
					filterForm.value = JSON.parse(filterFormFromQueryParams)
				}
				if (typeof selectedSortTypeFromQueryParams === 'string') {
					selectedSortType.value = JSON.parse(selectedSortTypeFromQueryParams)
				}
				if (pageNumberFromQueryParams && !isNaN(+pageNumberFromQueryParams)) {
					pageNumber.value = +pageNumberFromQueryParams
				}
			} catch (e) {}
		}

		function setQueryParams() {
			router.replace({
				path: route.path,
				query: {
					filterForm: JSON.stringify(filterForm.value),
					selectedSortType: JSON.stringify(selectedSortType.value),
					pageNumber: pageNumber.value
				}
			})
		}

		function resetFilter() {
			window.location.replace(route.path)
		}

		function getProductStateColor(type: string) {
			const colors: {
				[key: string]: string
			} = {
				described: '#9D9D9D',
				collected: '#8F57EB',
				shooting: '#E07900',
				published: '#00B94A',
				unavailable: '#E92121',
				shooted: '#21B6BF'
			}
			return colors[type]
		}

		async function onSelectSort(sortTypeKey: string) {
			if (sortTypeKey === selectedSortType.value.key) {
				if (selectedSortType.value.direction === 'DESC') {
					selectedSortType.value.direction = 'ASC'
				} else if (selectedSortType.value.direction === 'ASC') {
					selectedSortType.value.direction = 'DESC'
				}
				return
			}
			selectedSortType.value = {
				key: sortTypeKey,
				direction: 'DESC'
			}
		}

		async function getMainCategoryByGender(gender: undefined | string | number) {
			try {
				if (gender === '' || gender == null) return
				return (await axios.get('internal/main-category-list-by-gender/' + gender)).data
			} catch (e) {
				return []
			}
		}

		async function getSubCategoryByCategoryId(categoryId: undefined | string | number) {
			try {
				if (categoryId === '' || categoryId == null) return
				return (await axios.get('internal/sub-category-list-by-category-id/' + categoryId)).data
			} catch (e) {
				return []
			}
		}

		async function fetchProductList() {
			try {
				const filter = {
					...filterForm.value
				}
				if (filter.category === '') delete filter.category
				if (filter.gender === '') delete filter.gender
				if (!isFilledArray(filter.states)) delete filter.states
				if (!isFilledArray(filter.lots)) delete filter.lots
				if (!isFilledArray(filter.subCategories)) delete filter.subCategories
				const resBody = {
					page: pageNumber.value - 1,
					limit: countLoad,
					sort: selectedSortType.value,
					filter
				}
				const res = (await axios.post('/product/list', resBody)).data
				count.value = res.count
				// eslint-disable-next-line  @typescript-eslint/no-explicit-any
				products.value = res.rows.map((product: any) => {
					if (isFilledArray(product?.medium)) {
						// eslint-disable-next-line  @typescript-eslint/no-explicit-any
						product.photo = getUrlPhoto(product.medium.find((media: any) => media?.order === 1))
					}
					return product
				})
			} catch (e) {
				console.error(e)
				count.value = 0
				products.value = []
			}
		}
		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		function getProductTitle(product: any) {
			const productName = product?.name
			let title = ''
			if (productName) {
				title += productName
			} else {
				title += `${product?.type?.name} ${product?.brand?.name} ${getColorsStr(product?.colors)}`
			}
			title += ` (${prettyPrice(product.price)} ₽)`
			return title
		}

		function getUrlPhoto(media: IFile) {
			const project = process.env.VUE_APP_ENV === 'dev' ? 'dev' : 'production'

			return getUrlFile({
				entityType: media.entityType,
				state: media.state,
				project,
				extension: media.extension,
				size: 'xs',
				id: media.id,
				server: media.server
			})
		}

		async function getLotList() {
			try {
				return (await axios.get('/lot/list-all')).data
			} catch (e) {
				return []
			}
		}

		function getColorsStr(arr: Array<{ names: string }> | null) {
			if (!arr) return ''

			return ' ' + arr.map(el => el.names.replace('цвета', '').replace(' ', '')).join(', ') + ' цвета'
		}

		return {
			onSelectSort,
			selectedSortType,
			lots,
			categories,
			subCategories,
			localizeGender,
			filterForm,
			count,
			sortTypes,
			pageCount,
			products,
			pageNumber,
			localizeProductStates,
			enumProductState,
			getProductStateColor,
			resetFilter,
			getProductTitle
		}
	}
})
