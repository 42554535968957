import { IBuildUrlFile } from '@/interfaces'
import { enumMediaEntityType, enumMediaState } from '@/constains'

export type typeGetUrlFile = (options: IBuildUrlFile) => string | null

export const getUrlFile: typeGetUrlFile = options => {
	if (options.state === enumMediaState.uploaded) return null

	const shortPathId = [options.id.slice(0, 2), options.id.slice(2, 4), options.id.slice(4, 6)]

	return [
		'https://' + options.server + '.autlet.market/storage',
		options.project,
		enumMediaState[options.state],
		enumMediaEntityType[options.entityType],
		...shortPathId,
		options.id + '_' + options.size + options.extension
	].join('/')
}
